import $ from 'jquery';


$(function($) {

  $(".mhandle").click(function(e) {
    e.preventDefault();
    $(this).closest("header").toggleClass("open");
  });
  
  $("section.intro").each(function(e) {
    var $this=$(this);
    var items=$this.find(".intro-items>li");
    var index=0;
    var prev=$this.find(".intro-nav>.prev");
    var next=$this.find(".intro-nav>.next");
    
    prev.click(function(e) {
      e.preventDefault();
      index=(index+1)%items.length;
      updateActive();
    });
    
    next.click(function(e) {
      e.preventDefault();
      index=(index+items.length-1)%items.length;
      updateActive();
    });
    
    var updateActive=function() {
      items.eq(0).css("margin-left", -100*index+"%");
    }
    
  });
  
  
});
